<template>
  <div>
    <CCard>
        <CCardHeader>
        <CIcon name="cil-book"/> <span  v-if="title == '/my_uploads'">My Uploaded</span>
        <span  v-else>Study</span>
        Contents
        <div class="card-header-actions">
            <small class="text-muted">
                <CButton color="primary" @click="add_contents" v-if="title == '/my_uploads'">Add + </CButton>
                <CButton color="primary" @click="add_contents" v-if="user_role_id == '8de886dd-12e5-4b02-b812-187639513779' || 
                user_role_id == '6b82d8f3-e879-42de-a8ff-2426b61c9ab9' || user_role_id == '6b82d8f3-e879-42de-a8ff-2426b61c9ac9'
                ">Add + </CButton>
            </small>
        </div>
        </CCardHeader>
        <CCardBody v-if="content_data != ''">
            <CCard v-for="(item,index) in content_data" :key="index">
                <CCardHeader>
                    <CIcon name="cil-justify-center"/>
                    <strong> {{item.title}} </strong>
                    <div class="card-header-actions">
                    <a 
                        href="https://coreui.io/vue/docs/components/jumbotron" 
                        class="card-header-action" 
                        rel="noreferrer noopener" 
                        target="_blank"
                    >
                        <small class="text-muted">PDF</small>
                    </a>
                    </div>
                </CCardHeader>
                <CCardBody>
                    <CJumbotron style="padding:20px; padding-left:30px;">
                    <h4>{{item.subject_name}} - {{item.title}}</h4>
                    <p class="lead">{{item.remarks}}</p>
                    <p>{{item.class_standard}}</p>
                    <a  :href="'https://mahavidya-contents.s3.ap-south-1.amazonaws.com/'+item.file_type" target="_blank" class="mx-3">
                        <CIcon name="cil-book"/>
                    </a>&nbsp;
                    <CButton  size = "sm" color="info" variant="outline" @click="open_file(item.file_type)"><CIcon name="cil-cloud-download"/></CButton>
                    </CJumbotron>
                </CCardBody>
            </CCard>
        </CCardBody>
        <CCardBody v-else>
            <p style="text-align: center;color:red;"> No Contents Available !!</p>
        </CCardBody>
        <CRow style="margin-bottom:5px;">
            <CCol md="10"></CCol>
            <CCol md="2">
                <CButton size="sm"  color="primary">View More</CButton>
            </CCol>
        </CRow>
    </CCard>
    
    
  </div>
</template>

<script>
import axios from 'axios';
import pdf from 'vue-pdf';
export default {
  name: "study_content",
  data(){
      return{
          content_data:[],
          title:"",
          user_role_id :"",
          id:"",
          user_id:""
      }
  },
  components: {
      pdf: pdf
  },
  methods:{
      open_file(item){
        this.file_link = item;
        var url = 'https://mahavidya-contents.s3.ap-south-1.amazonaws.com/' +this.file_link;
        window.open(url, "_blank");
             
      },
      add_contents(){
          this.$router.push('/add_study_content');
      },
      get_content(){
          var url = '';
          if(this.user_role_id == '8de886dd-12e5-4b02-b812-187639513779'){
              var url = 'https://3t23rkamvh.execute-api.ap-south-1.amazonaws.com/Prod/api/Contents/GetTeacherContent/';
           //   var url = 'https://localhost:44331/api/Contents/GetTeacherContent/';
          }else if(this.user_role_id == '6b82d8f3-e879-42de-a8ff-2426b61c9ab9'){
              var url = 'https://3t23rkamvh.execute-api.ap-south-1.amazonaws.com/Prod/api/Contents/GetSchoolContent/';
             // var url = 'https://localhost:44331/api/Contents/GetSchoolContent/';
          }else{
              var url = 'https://3t23rkamvh.execute-api.ap-south-1.amazonaws.com/Prod/api/Contents/GetStudentContent/';
            // var url = 'https://localhost:44331/api/Contents/GetStudentContent/';
          }
           axios.get(url+this.id).then(response => {
              console.log(response);
              this.content_data = response.data;
          }).catch(error => {
              console.log(error);
          })
      }
  },
  created(){
    this.user_id = localStorage.getItem('id');
    let user_id = this.$session.get('user_id');
    this.user_role_id = this.$session.get('user_role_id');
    if(user_id == undefined || user_id == null)
    {
      return '/';
      //this.$router.push('/auth/login');
    } 
    if(this.user_role_id == '8290f938-b20a-42e3-c201-08d8f3241655'){
        this.id = this.user_id;
    }else if(this.user_role_id == '6b82d8f3-e879-42de-a8ff-2426b61c9ab9'){
        this.id = '8EE1D113-3D0D-4F94-12EE-08D8F417F6E3';
    }else{
        this.id = this.user_id;
    }
    this.get_content();
    this.title = this.$route.fullPath;
  }
};
</script>
